import { render, staticRenderFns } from "./b-card-input-validation.vue?vue&type=template&id=bc631dc8&scoped=true&"
import script from "./b-card-input-validation.ts?vue&type=script&lang=ts&"
export * from "./b-card-input-validation.ts?vue&type=script&lang=ts&"
import style0 from "./b-card-input-validation.vue?vue&type=style&index=0&id=bc631dc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc631dc8",
  null
  
)

export default component.exports